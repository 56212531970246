var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "mt-10 u-blue-text" }, [
    _c("h1", [_vm._v("401 - " + _vm._s(_vm.$t("Unauthorized")))]),
    _c("h3", [_vm._v(_vm._s(_vm.$t("UnauthorizedText")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }